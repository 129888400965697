var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title"),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c(
            "ics-page-inner",
            { attrs: { title: "个性化配置" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "overallAppForm",
                      attrs: {
                        model: _vm.overallAppForm,
                        rules: _vm.overallRules,
                        "label-width": "170px",
                        "label-suffix": _vm.constants.labelSuffix
                      }
                    },
                    [
                      _c(
                        "ics-page-inner",
                        { attrs: { title: "系统全局" } },
                        [
                          _c("template", { slot: "btn-inner" }, [
                            _c(
                              "div",
                              { staticClass: "btn-inner" },
                              [
                                !_vm.overallEdit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickEdit("overall")
                                          }
                                        }
                                      },
                                      [_vm._v(" 编辑 ")]
                                    )
                                  : _vm._e(),
                                _vm.overallEdit
                                  ? _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.clickCancel("overall")
                                          }
                                        }
                                      },
                                      [_vm._v(" 取消 ")]
                                    )
                                  : _vm._e(),
                                _vm.overallEdit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.clickOverallSubmit }
                                      },
                                      [_vm._v(" 提交 ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          !_vm.overallEdit
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "浏览器页签Logo" } },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "32px",
                                              height: "32px",
                                              display: "block"
                                            },
                                            attrs: {
                                              src: _vm.overallAppForm.logo,
                                              "preview-src-list": [
                                                _vm.overallAppForm.logo
                                              ]
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "浏览器网页名称" } },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.overallAppForm.wangName
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "系统名称" } },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.overallAppForm.sysName
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.overallEdit
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "浏览器页签Logo" } },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              staticClass: "avatar-uploader",
                                              attrs: {
                                                action: _vm.fileUploadUrl,
                                                headers: _vm.headers,
                                                "show-file-list": false,
                                                "on-success":
                                                  _vm.onSuccessOverallLogo,
                                                "on-error": _vm.onError,
                                                "before-upload":
                                                  _vm.beforeUploadOverallLogo
                                              }
                                            },
                                            [
                                              _vm.overallAppForm.logo
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "32px",
                                                      height: "32px",
                                                      display: "block"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.overallAppForm.logo
                                                    }
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "el-icon-plus avatar-uploader-icon"
                                                  }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "el-upload__tip",
                                                  staticStyle: {
                                                    width: "170px"
                                                  },
                                                  attrs: { slot: "tip" },
                                                  slot: "tip"
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "页签图标尺寸限制为32 x 32"
                                                    )
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        color: "red"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " *注：附件名称不能带有特殊符号 "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "浏览器网页名称" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder:
                                                "请输入浏览器网页名称"
                                            },
                                            model: {
                                              value:
                                                _vm.overallAppForm.wangName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.overallAppForm,
                                                  "wangName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "overallAppForm.wangName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "系统名称" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入系统名称"
                                            },
                                            model: {
                                              value: _vm.overallAppForm.sysName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.overallAppForm,
                                                  "sysName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "overallAppForm.sysName"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "localAppForm",
                      attrs: {
                        model: _vm.localAppForm,
                        rules: _vm.localRules,
                        "label-width": "170px",
                        "label-suffix": _vm.constants.labelSuffix
                      }
                    },
                    [
                      _c(
                        "ics-page-inner",
                        { attrs: { title: "登陆/注册页" } },
                        [
                          _c("template", { slot: "btn-inner" }, [
                            _c(
                              "div",
                              { staticClass: "btn-inner" },
                              [
                                !_vm.localEdit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.clickEdit("local")
                                          }
                                        }
                                      },
                                      [_vm._v(" 编辑 ")]
                                    )
                                  : _vm._e(),
                                _vm.localEdit
                                  ? _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.clickCancel("local")
                                          }
                                        }
                                      },
                                      [_vm._v(" 取消 ")]
                                    )
                                  : _vm._e(),
                                _vm.localEdit
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.clickLocalSubmit }
                                      },
                                      [_vm._v(" 提交 ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          !_vm.localEdit
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Logo" } },
                                        [
                                          _c("el-image", {
                                            staticClass: "avatar",
                                            attrs: {
                                              src: _vm.localAppForm.logo,
                                              "preview-src-list": [
                                                _vm.localAppForm.logo
                                              ]
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "登陆/注册页背景色" }
                                        },
                                        [
                                          _c("el-color-picker", {
                                            staticClass: "theme-picker",
                                            attrs: {
                                              disabled: true,
                                              predefine: [
                                                "#409EFF",
                                                "#1890ff",
                                                "#304156",
                                                "#212121",
                                                "#11a983",
                                                "#13c2c2",
                                                "#6959CD",
                                                "#f5222d"
                                              ],
                                              "popper-class":
                                                "theme-picker-dropdown"
                                            },
                                            model: {
                                              value:
                                                _vm.localAppForm
                                                  .backgroundColor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localAppForm,
                                                  "backgroundColor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localAppForm.backgroundColor"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "登陆/注册页背景图" }
                                        },
                                        [
                                          _c("el-image", {
                                            staticClass: "avatar",
                                            attrs: {
                                              src:
                                                _vm.localAppForm.backgroundMap,
                                              "preview-src-list": [
                                                _vm.localAppForm.backgroundMap
                                              ]
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.localEdit
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Logo" } },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              staticClass: "avatar-uploader",
                                              attrs: {
                                                action: _vm.fileUploadUrl,
                                                headers: _vm.headers,
                                                "show-file-list": false,
                                                "on-success":
                                                  _vm.onSuccessLocalLogo,
                                                "on-error": _vm.onError,
                                                "before-upload":
                                                  _vm.beforeUploadLocalLogo
                                              }
                                            },
                                            [
                                              _vm.localAppForm.logo
                                                ? _c("img", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      src: _vm.localAppForm.logo
                                                    }
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "el-icon-plus avatar-uploader-icon"
                                                  }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "el-upload__tip",
                                                  staticStyle: {
                                                    width: "180px"
                                                  },
                                                  attrs: { slot: "tip" },
                                                  slot: "tip"
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "logo图尺寸限制为2199 x 1157"
                                                    )
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        color: "red"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " *注：附件名称不能带有特殊符号 "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "登陆/注册页背景色" }
                                        },
                                        [
                                          _c("el-color-picker", {
                                            staticClass: "theme-picker",
                                            attrs: {
                                              predefine: [
                                                "#409EFF",
                                                "#1890ff",
                                                "#304156",
                                                "#212121",
                                                "#11a983",
                                                "#13c2c2",
                                                "#6959CD",
                                                "#f5222d"
                                              ],
                                              "popper-class":
                                                "theme-picker-dropdown"
                                            },
                                            model: {
                                              value:
                                                _vm.localAppForm
                                                  .backgroundColor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localAppForm,
                                                  "backgroundColor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localAppForm.backgroundColor"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "登陆/注册页背景图" }
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              staticClass: "avatar-uploader",
                                              attrs: {
                                                action: _vm.fileUploadUrl,
                                                headers: _vm.headers,
                                                "show-file-list": false,
                                                "on-success":
                                                  _vm.onSuccessLocalBackgroundMap,
                                                "on-error": _vm.onError,
                                                "before-upload":
                                                  _vm.beforeUploadLocalBackgroundMap
                                              }
                                            },
                                            [
                                              _vm.localAppForm.backgroundMap
                                                ? _c("img", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      src:
                                                        _vm.localAppForm
                                                          .backgroundMap
                                                    }
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "el-icon-plus avatar-uploader-icon"
                                                  }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "el-upload__tip",
                                                  staticStyle: {
                                                    width: "180px"
                                                  },
                                                  attrs: { slot: "tip" },
                                                  slot: "tip"
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "背景图尺寸限制为1920 x 1080"
                                                    )
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        color: "red"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " *注：附件名称不能带有特殊符号 "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }