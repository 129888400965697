<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-page-inner title="个性化配置">
        <el-col :span="24">
          <el-form ref="overallAppForm" :model="overallAppForm" :rules="overallRules" label-width="170px" :label-suffix="constants.labelSuffix">
            <ics-page-inner title="系统全局">
              <template slot="btn-inner">
                <div class="btn-inner">
                  <el-button v-if="!overallEdit" type="primary" @click="clickEdit('overall')">
                    编辑
                  </el-button>
                  <el-button v-if="overallEdit" @click="clickCancel('overall')">
                    取消
                  </el-button>
                  <el-button v-if="overallEdit" type="primary" @click="clickOverallSubmit">
                    提交
                  </el-button>
                </div>
              </template>
              <div v-if="!overallEdit">
                <el-col :span="24">
                  <el-form-item label="浏览器页签Logo">
                    <el-image :src="overallAppForm.logo" style="width: 32px;height: 32px;display: block;" :preview-src-list="[overallAppForm.logo]" />
                  </el-form-item>
                  <el-form-item label="浏览器网页名称">
                    <p>{{ utils.isEffectiveCommon(overallAppForm.wangName) }}</p>
                  </el-form-item>
                  <el-form-item label="系统名称">
                    <p>{{ utils.isEffectiveCommon(overallAppForm.sysName) }}</p>
                  </el-form-item>
                </el-col>
              </div>
              <div v-if="overallEdit">
                <el-col :span="24">
                  <el-form-item label="浏览器页签Logo">
                    <el-upload
                      class="avatar-uploader"
                      :action="fileUploadUrl"
                      :headers="headers"
                      :show-file-list="false"
                      :on-success="onSuccessOverallLogo"
                      :on-error="onError"
                      :before-upload="beforeUploadOverallLogo"
                    >
                      <img v-if="overallAppForm.logo" :src="overallAppForm.logo" style="width: 32px;height: 32px;display: block;">
                      <i v-else class="el-icon-plus avatar-uploader-icon" />
                      <div slot="tip" class="el-upload__tip" style="width: 170px;">
                        <p>页签图标尺寸限制为32 x 32</p>
                        <p style="color: red;">
                          *注：附件名称不能带有特殊符号
                        </p>
                      </div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="浏览器网页名称">
                    <el-input v-model="overallAppForm.wangName" placeholder="请输入浏览器网页名称" />
                  </el-form-item>
                  <el-form-item label="系统名称">
                    <el-input v-model="overallAppForm.sysName" placeholder="请输入系统名称" />
                  </el-form-item>
                </el-col>
              </div>
            </ics-page-inner>
          </el-form>
          <el-form ref="localAppForm" :model="localAppForm" :rules="localRules" label-width="170px" :label-suffix="constants.labelSuffix">
            <ics-page-inner title="登陆/注册页">
              <template slot="btn-inner">
                <div class="btn-inner">
                  <el-button v-if="!localEdit" type="primary" @click="clickEdit('local')">
                    编辑
                  </el-button>
                  <el-button v-if="localEdit" @click="clickCancel('local')">
                    取消
                  </el-button>
                  <el-button v-if="localEdit" type="primary" @click="clickLocalSubmit">
                    提交
                  </el-button>
                </div>
              </template>
              <div v-if="!localEdit">
                <el-col :span="24">
                  <el-form-item label="Logo">
                    <el-image :src="localAppForm.logo" class="avatar" :preview-src-list="[localAppForm.logo]" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="登陆/注册页背景色">
                    <el-color-picker
                      v-model="localAppForm.backgroundColor"
                      :disabled="true"
                      :predefine="['#409EFF', '#1890ff', '#304156','#212121','#11a983', '#13c2c2', '#6959CD', '#f5222d', ]"
                      class="theme-picker"
                      popper-class="theme-picker-dropdown"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="登陆/注册页背景图">
                    <el-image :src="localAppForm.backgroundMap" class="avatar" :preview-src-list="[localAppForm.backgroundMap]" />
                  </el-form-item>
                </el-col>
              </div>
              <div v-if="localEdit">
                <el-col :span="24">
                  <el-form-item label="Logo">
                    <el-upload
                      class="avatar-uploader"
                      :action="fileUploadUrl"
                      :headers="headers"
                      :show-file-list="false"
                      :on-success="onSuccessLocalLogo"
                      :on-error="onError"
                      :before-upload="beforeUploadLocalLogo"
                    >
                      <img v-if="localAppForm.logo" :src="localAppForm.logo" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon" />
                      <div slot="tip" class="el-upload__tip" style="width: 180px;">
                        <p>logo图尺寸限制为2199 x 1157</p>
                        <p style="color: red;">
                          *注：附件名称不能带有特殊符号
                        </p>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="登陆/注册页背景色">
                    <el-color-picker
                      v-model="localAppForm.backgroundColor"
                      :predefine="['#409EFF', '#1890ff', '#304156','#212121','#11a983', '#13c2c2', '#6959CD', '#f5222d', ]"
                      class="theme-picker"
                      popper-class="theme-picker-dropdown"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="登陆/注册页背景图">
                    <el-upload
                      class="avatar-uploader"
                      :action="fileUploadUrl"
                      :headers="headers"
                      :show-file-list="false"
                      :on-success="onSuccessLocalBackgroundMap"
                      :on-error="onError"
                      :before-upload="beforeUploadLocalBackgroundMap"
                    >
                      <img v-if="localAppForm.backgroundMap" :src="localAppForm.backgroundMap" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon" />
                      <div slot="tip" class="el-upload__tip" style="width: 180px;">
                        <p>背景图尺寸限制为1920 x 1080</p>
                        <p style="color: red;">
                          *注：附件名称不能带有特殊符号
                        </p>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </div>
            </ics-page-inner>
          </el-form>
        </el-col>
      </ics-page-inner>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: {},
  mixins: [routeEnterMixin],
  data () {
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/open/upload?bucket=other',
      headers: {
        Token: this.$store.state.token
      },
      overallAppForm: {
        logo: '',
        sysName: '',
        wangName: ''
      },
      localAppForm: {
        backgroundColor: '',
        backgroundMap: '',
        logo: ''
      },
      localEdit: false,
      overallEdit: false,
      dialog: {
        local: {
          logo: {
            visible: false,
            title: '上传LOGO'
          },
          backgroundMap: {
            visible: false,
            title: '上传背景图'
          },
        },
        overall:{
          logo: {
            visible: false,
            title: '上传LOGO'
          }
        },
      },
      overallRules: {},
      localRules: {},
      rules: {},
    }
  },
  created () {
    this.getConfigureDetail()
  },
  methods: {
    getConfigureDetail () {
      this.api.system.configure.getConfigureDetail().then(result => {
        const global = result.data.data.global
        const logonPage = result.data.data.logonPage
        this.overallAppForm = global
        this.localAppForm = result.data.data.logonPage
      }).finally(() => {
          this.loading.detail = false
        })
    },
    clickEdit (type) {
      if (type === 'local') {
        this.localEdit = true
      } else {
        this.overallEdit = true
      }
    },
    clickCancel (type) {
      if (type === 'local') {
        this.localEdit = false
      } else {
        this.overallEdit = false
      }
    },
    clickDialog () {
      this.dialog.local.backgroundMap.visible = true
    },
    onSuccessOverallLogo (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.overallAppForm.logo = data.url
    },
    beforeUploadOverallLogo (file) {
      const isPNG = file.type === 'image/x-icon'
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isPNG) {
        this.$message.error('上传图片只能是ico格式!')
        return false
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过1M!',
          type: 'error'
        })
        return false
      }
      const isSize = new Promise((resolve, reject) => {
        const width = 32
        const height = 32
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = () => {
          const valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          this.$message.error('图片尺寸限制为32 x 32')
          return Promise.reject()
        }
      )
      return isPNG && isLt2M && isSize
    },
    beforeUploadLocalLogo (file) {
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isPNG) {
        this.$message.error('上传图片只能是png格式!')
        return false
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过1M!',
          type: 'error'
        })
        return false
      }
      const isSize = new Promise((resolve, reject) => {
        const width = 2199
        const height = 1157
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = () => {
          const valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          this.$message.error('图片尺寸限制为2199 x 1157')
          return Promise.reject()
        }
      )
      return isPNG && isLt2M && isSize
    },
    onSuccessLocalLogo (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.localAppForm.logo = data.url
    },
    onRemoveLocalLogo (file) {
      this.localAppForm.logo = ''
      this.localAppForm.logoFileList = this.localAppForm.logoFileList.filter(function (item) {
        return item.url !== file.url
      })
    },
    beforeUploadLocalBackgroundMap (file) {
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isPNG) {
        this.$message.error('上传图片只能是png格式!')
        return false
      }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过1M!',
          type: 'error'
        })
        return false
      }
      const isSize = new Promise((resolve, reject) => {
        const width = 1920
        const height = 1080
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = () => {
          const valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          this.$message.error('图片尺寸限制为1920 x 1080')
          return Promise.reject()
        }
      )
      return isPNG && isLt2M && isSize
    },
    onSuccessLocalBackgroundMap (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.localAppForm.backgroundMap = data.url
    },
    onError (err, file) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
    },
    clickLocalSubmit () {
      this.api.system.configure.addLoginPage(this.localAppForm).then(result => {
        if (result.data.success === true) {
          this.$message.success('操作成功')
          this.clickCancel('local')
          this.detailLoginPage()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
      })
    },
    clickOverallSubmit () {
      this.api.system.configure.addSystemGlobal(this.overallAppForm).then(result => {
        if (result.data.success === true) {
          this.$message.success('操作成功')
          this.clickCancel('overall')
          this.detailSystemGlobal()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
      })
    },
    onSuccessFile () {},
  }
}
</script>

<style scoped>
.avatar {
  width: 78px;
  height: 78px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #454545;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
